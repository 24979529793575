<template>
  <!-- Tabla con flexbox de productos y clientes -->
  <section class="vista-impresion">
    <form
      class="tabla-pedidos"
      @submit.prevent
    >
      <div class="tabla-contenedor">
        <!-- Tabla de datos -->
        <div class="tabla-distribucion">
          <div :class="['thead', {'pt-2': !mostrarEncabezado}]">
            <div class="columna-principal">
              <div class="filas row-nowrap">
                <!-- Columna encabezado -->
                <header class="sticky-0 ancho-1 encabezado bold">
                  <span>Día / Mes - Hora</span>
                  <small>Pedido - Zona</small>
                  <small class="small-block font-xs">
                    Vendedor
                  </small>
                </header>

                <!-- Iteración de los datos de los pedidos -->
                <div
                  v-for="ped in pedidos"
                  :key="`fecha-${ped.id_ped}`"
                >
                  <div class="celda-checkbox flex-wrap">
                    <p class="col-12 p-0">
                      <small class="no-print font-xs">{{ formatearFechaYHora(ped.fec_cre_ped) }}</small>
                      <small class="bold">{{ped.id_ped}} - {{ped.est_zon}} </small>
                    </p>
                    <small class="font-xs">{{ ped.ven_nombre }}.</small>
                    <p class="clearfix">
                      <span v-if="nuevos.porPed[ped.id_ped]" class="mr-1">
                        <mdb-tooltip
                          trigger="hover"
                          :options="{placement: 'top'}"
                        >
                          <span slot="tip" class="fs-initial">
                            <small class="bold">CLIENTE NUEVO</small>
                          </span>
                          <font-awesome-icon
                            icon="user-tie"
                            slot="reference"
                          />
                        </mdb-tooltip>
                      </span>
                      <small v-if="nuevos.porPed[ped.id_ped]" class="d-inline-block">Cliente nuevo</small>
                    </p>
                  </div>
                </div>
              </div>
              <div class="filas row-nowrap">
                <header class="sticky-0 ancho-1 pt-0 encabezado bold">
                  Razón social
                  <small>
                    <span class="print-only">RIF</span>
                    Sucursal
                    <span class="no-print">, Código</span>
                  </small>
                  <small>
                    SADA
                  </small>
                  <small>
                    Encargado
                  </small>
                  <small
                    v-if="Object.values(pedidos).filter((ped) => ped.tel_per).length"
                    class="font-xs"
                  >
                    Teléfono
                  </small>
                  <template v-if="mostrarHorarios">
                    Horarios
                  </template>
                </header>

                <!-- Iteración de los datos de los pedidos -->
                <div
                  v-for="ped in pedidos"
                  :key="'s'+ped.id_ped"
                  class="text-capitalize text-break bold"
                >
                  {{ped.nom_emp}}
                  <small>
                    <span class="print-only">{{ ped.rif_emp}}</span>
                    {{ped.nom_suc}}{{ped.cod_suc ? ',' : ''}}
                    <span class="no-print">{{ped.cod_suc}}</span>
                  </small>
                  <small>{{ped.sada_suc}}.</small>
                  <small>
                    {{ped.sada_suc}}.
                  </small>
                  <small class="font-weight-bold d-inline">
                    {{ped.enc_nombre}}
                  </small>
                  <small
                    v-if="ped.tel_per"
                    class="font-xs"
                  >
                    +{{ped.tel_per}}
                  </small>
                  <template v-if="mostrarHorarios">
                    <p
                      v-for="(dia, d) in horarios[ped.id_suc]"
                      :key="'s'+ped.id_ped+d"
                      class="mb-0"
                    >
                      <small class="small-block">
                        {{ dias[Object.keys(dia) ] }}
                      </small>
                      <small
                        v-for="(horario, h) in dia"
                        :key="'s'+ped.id_ped+d+h"
                      >
                      {{ formatearHorario(horario) }}
                      </small>
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <div class="ancho-4 column-center encabezado text-right bold p-2" v-if="mostrarTotalesPresentacion">
              Cantidad a cargar
            </div>
            <div class="ancho-4 column-center encabezado text-right bold p-2" v-if="mostrarTotalesPresentacion">
              Peso a cargar
            </div>
          </div>
          <div class="tbody">
            <!-- Categoría -->
            <div
              v-for="cat in categorias"
              :key="'t'+cat.id_cat"
              :class="['row-nowrap', {'d-none': !categoriasAMostrar.includes(cat.id_cat)}]"
            >
              <template v-if="categoriasAMostrar.includes(cat.id_cat)">
                <!-- Nombre de categoría -->
                <div class="ancho-2 column-center encabezado bold sticky-0 p-0">
                  <div class="rotar-titulo">
                    {{cat.nom_cat}}
                  </div>
                </div>
                <!-- Producto -->
                <div class="productos">
                  <div
                    v-for="pro in cat.productos"
                    :key="`prod-${pro.id_pro}`"
                    class="row-nowrap"
                  >
                    <!-- Nombre de producto -->
                    <div class="column-center encabezado bold sticky-1 p-0">
                      <div class="ancho-2-2 ">
                        {{pro.nom_pro}}
                      </div>
                    </div>
                    <!-- Presentaciones -->
                    <div class="presentaciones">
                      <div
                        v-for="pre in pro.presentaciones"
                        :key="`pres-${pre.id_pre}`"
                        class="row-nowrap"
                      >
                        <!-- Nombre de presentación -->
                        <div class="ancho-3 sticky-2 encabezado px-1">
                          {{pre.des_pre}}
                          <small class="small-block">Código - {{pre.cod_pre}}</small>
                        </div>

                        <!-- Iteración de los datos de los pedidos -->
                        <div class="row-nowrap">
                          <div
                            v-for="(ped, i) in pedidos"
                            :key="`pedido-${ped.id_ped}`"
                            class="pedido column-center"
                          >
                            <div class="celda-checkbox justify-content-center">
                              {{cargaPedido[i][pre.id_pre] ? cargaPedido[i][pre.id_pre].cantidad : 0}} / {{cargaPedido[i][pre.id_pre] ? cargaPedido[i][pre.id_pre].peso + 'kg' : 0 + 'kg'}}
                              <span class="print-only"><i class="checkbox"></i></span>
                            </div>
                          </div>
                        <!-- Campos de totales a despachar y pedidos por presentación -->
                        <div class="pedido celda-numerica p-2" v-if="mostrarTotalesPresentacion">
                          <span title="Cantidad a cargar">
                            {{totalesPorPresentacion[pre.id_pre] ? convertirValorAFixed(totalesPorPresentacion[pre.id_pre].cantidad) : 0}}
                          </span>
                        </div>
                        <div class="pedido celda-numerica p-2" v-if="mostrarTotalesPresentacion">
                          <span title="Peso a cargar">
                            {{totalesPorPresentacion[pre.id_pre] ? convertirValorAFixed(totalesPorPresentacion[pre.id_pre].peso) : 0}}
                          </span>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
            <div class="row-nowrap">
              <div class="col-auto sticky-0 ancho-1 column-center encabezado bold">
                Totales por pedido
              </div>
              <div
                v-for="(ped,i) in totalesPorPresentacionPedidos"
                :key="`pedido-${i}`"
                class="pedido celda-numerica text-center column-center"
              >
                {{convertirValorAFixed(ped.cantidad)}}
                <small class="d-block">
                  {{convertirValorAFixed(ped.peso)}} kg
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Cantidad totales -->
      <div class="row align-items-center mx-0 bg-light-gray justify-content-end" v-if="mostrarTotalesPresentacion">
        <div class="col encabezado bold">
          Totales
        </div>
        <!-- Campos de totales a despachar y pedidos -->
        <div class="ancho-4 ancho-5-print column-center celda-numerica p-2">
          <p class="mb-0">
            <span title="Cantidad total pedida" class="font-weight-bold">
              {{convertirValorAFixed(totalesPorPresentacion.cantidad)}}
            </span>
          </p>
          <span class="encabezado bold">Cantidad a cargar</span>
        </div>
        <div class="ancho-4 ancho-5-print column-center celda-numerica p-2">
          <p class="mb-0">
            <span title="Peso total pedido" class="font-weight-bold">
              {{convertirValorAFixed(totalesPorPresentacion.peso)}} kg
            </span>
          </p>
          <span class="encabezado bold">Peso a cargar</span>
        </div>
      </div>
    </form>
  </section>
</template>

<script>
import {mdbTooltip} from 'mdbvue';
import {convertirNumeroAFixed, formatearFechaYHora} from '@/funciones/funciones.js';
export default {
  name: 'TablaDeImpresion',
  components: {
    mdbTooltip,
  },
  props: {
    datosPedidos: { // Datos de la tabla
      type: Object,
      default: () => {},
      required: true,
    },
    mostrarTotalesPresentacion: {
      type: Boolean,
      default: false,
    },
    mostrarHorarios: {
      type: Boolean,
      default: false,
    },
    titulo: {
      type: String,
      default: '',
    },
    // Para mostrar los filtros por categoría
    categorias: { // Lista de las categorías a filtrar
      type: Object,
      default: () => {},
    },
    // mostrarFiltrosCategoria: { // Activa los filtros
    //   type: Boolean,
    //   default: false
    // },
    cateMostrar: { // Distribucion categorias
      type: Array,
    },
    // Importados para evitar errores visuales que desconozco su funcionalidad
    totalesPorPresentacionPedidos: {
      type: Object,
      default: () => {},
    },
    totalesPorPresentacion: {
      type: Object,
      default: () => {},
    },
    cargaPedido: {
      type: Object,
      default: () => {},
    },
    horarios: {
      type: Object,
      default: () => {},
    },
    nuevos: {
      type: Object,
      default: () => {
        return {
          porPed: {},
          porSuc: {},
        };
      },
    },
  },
  data() {
    return {
      formatearFechaYHora,
      mostrarFiltrosCategoria: true,
      mostrarEncabezado: true,
      mostrarConfiguracion: false,
      dias: [
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
        'Domingo',
      ],
    };
  },
  computed: {
    pedidos() {
      return this.datosPedidos;
    },
    categoriasAMostrar() {
      return this.cateMostrar;
    },
  },
  methods: {
    convertirValorAFixed(numero) {
      return convertirNumeroAFixed(numero);
    },
    formatearHorario(horario) {
      const horaIni = [horario.hor_ini_hor.split(':')[0], horario.hor_ini_hor.split(':')[1]].join(':');
      const horaFin = [horario.hor_fin_hor.split(':')[0], horario.hor_fin_hor.split(':')[1]].join(':');
      return `${horaIni} - ${horaFin}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen {
  .vista-impresion {padding: 2rem 1rem 0;}
}
$tabla-separador: rgba(0, 0, 0, 0.05);

.tabla-pedidos { // Contenedor principal
  border: 1px solid $tabla-separador;
  border-radius: 6px;
  padding-bottom: .15rem;

  header[class*="titulo"] {
    font-size: 135%;
    text-align: center;
    text-transform: uppercase;
    padding: .75rem 0 .75rem 1rem;

    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }

  // Encabezados
  [class*="encabezado"] {
    color: #616161;
    font-size: .75rem;
    line-height: 1.3;
    text-transform: uppercase;
  }

  // Celda numerica
  .celda-numerica {
    font-size: .9rem;
    text-align: right;
  }

  // Negritas de 600
  .bold {font-weight: 600;}

  .bg-light-gray {
    @include bg-color-type-2 (#949494);
  }
}

// Tabla de distribución Zonas con flexbox
.tabla-contenedor { // Contenedor tabla
  overflow-x: auto;
  position: relative;
  width: 100%;
}

.tabla-contenedor {
  // Estilos a etiquetas generales
  small {display: block;}
  label {margin-bottom: 0;}

  // Estilizando las líneas divisorias horizontales
  & > div:not(:first-of-type),
  .tbody > .row-nowrap:not(:first-child),
  .productos > .row-nowrap:not(:first-child),
  .presentaciones > .row-nowrap:not(:first-child) {
    border-top: 1px solid $tabla-separador;
  }

  // Clases sticky para los encabezados
  [class*="sticky"] {
    background-color: #fff;
    position: sticky;
    z-index:1
  }

  .tabla-distribucion {
    min-width: 100%;
    width: max-content;

    .column-center {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    // Filas flex row nowrap
    .thead,
    .row-nowrap {
      display: flex;
      flex-flow: row nowrap;
    }
    .filas > div {
      font-size: .8rem;
      line-height: 1.1;
      padding: 0 .5rem;
      width: 150px;
    }

    .thead {
      background: #fff;
      border-bottom: 2px solid $tabla-separador;
      position: sticky;
      top: 0;
      z-index: 2;
    }

    .tbody {
      &> div:first-child {
        font-size: .8rem;
        min-width: max-content;
        padding: 0;
      }
      & > .row-nowrap.w-100 {
        @include bg-color-type-2 (#949494);
      }
    }

    .pedido {
      padding: 0 .5rem;
      width: 150px;
    }

    // Rotar titulo
    .rotar-titulo {
      font-size: .66rem;
      padding: .2rem;
      transform: scale(-1, -1);
      writing-mode: vertical-rl;
    }

    .columna-principal header.encabezado.bold {
      padding: 0.25rem 1rem;
    }
  }

  // Anchos de columna
  .ancho-1 {width: 244px;}
  .ancho-2 {width: 21px;}
  .ancho-2-2 {width: 100px;}
  .ancho-3 {width: 123px;}
  .ancho-4 {width: 150px;}

  // Clases sticky para los encabezados
  .sticky-0 {left: 0px;}
  .sticky-1 {left: 21px;}
  .sticky-2 {left: 121px;}
  // Se usa para la columna Totales
  .left-0 {
    left: 0;
    position: sticky;
    z-index: 0;
  }

  // celda-checkbox
  .celda-checkbox {
    align-items: center;
    display: flex;
    font-size: .9rem;
    justify-content: space-between;

    p {
      font-size: inherit;
      line-height: 1.3;
      margin-bottom: 0;
    }

    .checkbox {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: inherit;
      margin-left: .25rem;
      user-select: none;
      white-space: nowrap;
    }
    .checkbox input[type="checkbox"] {
      display: none;

      & + .icono-checkbox::before {
        @include agregar-icono('\f0c8', 400);
        color: $gris-tr-borde;
        font-size: 1.45rem;
        transition: color .5s ease;
      }
      &:checked + .icono-checkbox::before {
        @include agregar-icono('\f14a');
        color: $exitoso;
      }
    }
    & > .md-form {flex-grow: 2;}
  }
}
// Estableciendo tamaños fijos a la tabla
@media screen {
  .tabla-pedidos.maxH-80 .tabla-contenedor {
    max-height: 80vh;
  }
}
.font-xs {
  font-size: 70%;
}
.print-only-inline {
  display: none;
  @media print {
    display: inline;
  }
}
</style>
