<template>
  <transition
    name="custom-classes-transition"
    enter-active-class="animated fadeInRight"
    leave-active-class="animated fadeOutRight"
  >
    <aside
      v-if="mostrarAsideActividad"
      class="barra-lateral-derecha"
    >
      <div class="row align-items-center mx-0 my-1">
        <header class="col offset-3 offset-sm-1 px-0 h4-responsive text-center">
          Nueva actividad
        </header>
        <mdb-btn
          flat
          dark-waves
          icon="times"
          type="button"
          class="m-0 px-3 py-4"
          @click="cerrarNuevaActividad"
        />
        <span class="col-12 mt-sm-n3 text-center">
          {{empresa.nom_emp}}
        </span>
      </div>
      <div class="px-4 contenedor-opciones overflow-auto">
        <div class="row">
          <div class="col-12 my-2 px-0 px-sm-3">
            <v-select
            v-model="planificacion"
            :searchable="false"
            :options="Object.values(planificaciones)"
            :getOptionLabel="planificacion => planificacion.fec_pla"
            class="outline-datalist my-2"
          >
            <template #header>
              <label :class="['datalist-label']">
                Planificación
              </label>
            </template>
            <template #option="planificacion">
              {{ planificacion.fec_pla }} {{ planificacion.hor_pla }}
            </template>
            <template #no-options v-if="Object.keys(planificaciones).length">
              Selecciona una planificación
            </template>
            <template #no-options v-else>
              No se encontraron planificaciones pendientes
            </template>
          </v-select>
          </div>
          <header
            class="col-12 font-weight-bold mt-1"
            title="El medio de contacto es requerido"
          >
            Medio de contacto
            <span class="texto-error ml-1">*</span>
          </header>
          <div class="col-6 pl-0 pr-1 px-sm-3">
            <input
              v-model="actividades.contacto"
              id="visita"
              type="radio"
              name="contacto"
              :value="1"
            />
            <label for="visita" class="opciones-actividad">
              <font-awesome-icon icon="walking" />
              Visita
            </label>
          </div>
          <div class="col-6 pl-1 pr-0 px-sm-3">
            <input
              v-model="actividades.contacto"
              id="llamada"
              type="radio"
              name="contacto"
              :value="2"
            />
            <label for="llamada" class="opciones-actividad">
              <font-awesome-icon icon="phone-alt" />
              Llamada
            </label>
          </div>
        </div>
        <header
          title="El pedido es requerido"
          class="font-weight-bold"
        >
          Pedido
          <span class="texto-error ml-1">*</span>
        </header>
        <div class="row">
          <div class="col-5 col-sm-6 pl-0 pr-1 px-sm-3">
            <input
              v-model="actividades.pedido"
              id="no-hizo"
              type="radio"
              name="pedido"
              :value="4"
            />
            <label for="no-hizo" class="opciones-actividad">
              <font-awesome-icon icon="ban" />
              No hizo
            </label>
          </div>
          <div class="col-7 col-sm-6 pl-1 pr-0 px-sm-3">
            <input
              v-model="actividades.pedido"
              id="tomar-pedido"
              type="radio"
              name="pedido"
              :value="3"
            />
            <label for="tomar-pedido" class="opciones-actividad" @click="abrirTomarCantidad('pedido')">
              <font-awesome-icon icon="clipboard-list" />
              Tomar pedido
            </label>
          </div>
        </div>
        <header
          class="font-weight-bold"
          :title="this.actividades.pedido === 3 && !this.actividades.inventario
            ? 'El inventario es requerido' : 'Selecciona una opción'"
        >
          Inventario
          <span
            v-if="this.actividades.pedido === 3 && !this.actividades.inventario"
            class="texto-error ml-1"
          >
            *
          </span>
        </header>
        <div class="row">
          <div class="col-5 col-sm-6 pl-0 pr-1 px-sm-3">
            <input
              v-model="actividades.inventario"
              id="no-tiene"
              type="radio"
              name="inventario"
              :value="11"
            />
            <label for="no-tiene" class="opciones-actividad">
              <font-awesome-icon icon="ban" />
              No tiene
            </label>
          </div>
          <div class="col-7 col-sm-6 pl-1 pr-0 px-sm-3">
            <input
              v-model="actividades.inventario"
              id="tomar-inventario"
              type="radio"
              name="inventario"
              :value="7"
            />
            <label for="tomar-inventario" class="opciones-actividad" @click="abrirTomarCantidad('inventario')">
              <font-awesome-icon icon="boxes" />
              Tomar inventario
            </label>
          </div>
        </div>
        <header class="font-weight-bold">Cobranza</header>
        <div class="row">
          <div class="col-6 pl-0 pr-1 px-sm-3">
            <input
              v-model="actividades.cobranza"
              id="no-efectiva"
              type="radio"
              name="cobranza"
              :value="9"
            />
            <label for="no-efectiva" class="opciones-actividad">
              <font-awesome-icon icon="ban" />
              No efectiva
            </label>
          </div>
          <div class="col-6 pl-1 pr-0 px-sm-3">
            <input
              v-model="actividades.cobranza"
              name="cobranza"
              type="radio"
              id="efectiva"
              :value="6"
              @click="obtenerBancos(); obtenerMonedas(); obtenerMetodos(); FormularioPagos = true"
            />
            <label for="efectiva" class="opciones-actividad">
              <font-awesome-icon icon="money-bill-wave" />
              Efectiva
            </label>
          </div>
        </div>
        <header class="font-weight-bold">Adicionales</header>
        <div class="row">
          <div class="col-6 pl-0 pr-1 px-sm-3">
            <input
              v-model="actividades.adicionales"
              id="entrega-pedido"
              type="checkbox"
              name="adicionales"
              :value="5"
            />
            <label for="entrega-pedido" class="opciones-actividad">
              <font-awesome-icon icon="shipping-fast" />
              Entrega pedido
            </label>
          </div>
          <div class="col-6 pl-1 pr-0 px-sm-3">
            <input
              v-model="actividades.adicionales"
              id="merchandising"
              type="checkbox"
              name="adicionales"
              :value="10"
              />
            <label for="merchandising" class="opciones-actividad">
              <font-awesome-icon icon="store" />
              Merchandising
            </label>
          </div>
        </div>
        <header class="font-weight-bold">Devoluciones</header>
        <div class="row justify-content-around">
          <div class="col-12 col-sm-6 col-md-auto px-0 px-sm-3">
            <input
              v-model="actividades.devoluciones"
              id="credito"
              type="radio"
              name="devoluciones"
              :value="12"
            />
            <label for="credito" class="opciones-actividad" @click="abrirTomarCantidad('devolucion')">
              <font-awesome-icon icon="file-invoice" />
              Tomar nota de crédito
            </label>
          </div>
        </div>
        <header class="font-weight-bold mt-3">Observaciones</header>
        <div class="custom-control custom-switch secundario">
          <input
            v-model="observacionSwitch"
            id="obs-switch"
            type="checkbox"
            class="custom-control-input secundario"
            @click="observacionSwitch = !observacionSwitch"
          >
          <label
            class="custom-control-label"
            for="obs-switch"
          >
            <span class="texto">
              Agregar observación a la actividad
            </span>
          </label>
        </div>
        <div class="overflow-hidden">
          <transition
            name="fading-classes-transition"
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOutUp"
            v-if="observacionSwitch"
          >
            <mdb-input
              label="Observaciones"
              v-model="observaciones"
              type="textarea"
              class="mt-2 mb-0 textarea-mb-0"
              :rows="3"
              outline
            />
          </transition>
        </div>
      </div>
      <div :class="[!soloEnviarActividad ? 'justify-content-around' : 'justify-content-center', 'row mx-0 my-2 align-items-center']">
        <div class="col-12 col-sm-auto col-md-12 order-sm-2 order-md-1 text-center">
          <mdb-btn
            type="submit"
            color="primario"
            icon="check"
            class="mx-0"
            @click="enviarActividad"
            :disabled="botonDeshabilitado"
          >
            Enviar
          </mdb-btn>
        </div>
        <div class="col-12 col-sm-auto col-md-12 order-sm-1 order-md-2 px-0 text-center">
          <mdb-btn
            v-if="!soloEnviarActividad"
            flat
            dark-waves
            type="button"
            icon="user-clock"
            class="mx-0 px-3"
            @click="guardarEnviarLuego"
          >
            Guardar y enviar luego
          </mdb-btn>
        </div>

        <AlertaMensaje
          :alertaMensaje="alertaMensaje"
          @cerrar="alertaMensaje.contenido = ''"
        />
        <ModalFormularioPagos
          :mostrarModalFormPagos="FormularioPagos"
          :esActividad="true"
          :datosCliente="{
            id_emp: adicionalesDatosPago.id_emp,
            nom_emp: adicionalesDatosPago.nom_emp,
            rif_emp: adicionalesDatosPago.rif_emp
          }"
          :datosSucursal="{
            id_emp_suc: adicionalesDatosPago.id_emp_suc,
            id_suc: adicionalesDatosPago.id_suc,
            id_tcl_suc: adicionalesDatosPago.id_tcl_suc,
            id_zon_suc: adicionalesDatosPago.id_zon_suc,
            nom_suc: adicionalesDatosPago.nom_suc
          }"
          :pagoActualGuardado="pagoActualGuardado"
          :BancosPago="BancosPago"
          :MetodosPago="MetodosPago"
          :MonedasPago="MonedasPago"
          @crearPagoActividad="tomarPago($event)"
          @guardarDatosPagoActividad="guardarPagoActual($event)"
          @alertaMensaje="alertaMensaje = $event"
          @cerrar="FormularioPagos = false"
        />
        <TomarCantidad
          :esDevolucion="esDevolucion"
          :esInventario="esInventario"
          :esPedido="esPedido"
          :sucursal="{nombre:empresa.nom_emp, id:idSucursal}"
          :mostrarModal="mostrarModalTomarCantidad"
          :inventarioGuardado="inventario"
          :pedidoGuardado="pedido"
          :devolucionGuardada="devolucion"
          @tomarInventario="tomarInventario($event)"
          @tomarPedido="tomarPedido($event)"
          @tomarDevolucion="tomarDevolucion($event)"
          @cancelarDevolucion="actividades.devoluciones = []"
          @alertaMensaje="alertaMensaje = $event"
          @cerrar="mostrarModalTomarCantidad=false; esDevolucion=false; esInventario=false; esPedido=false;"
        />
      </div>
    </aside>
  </transition>
</template>

<script>
import {mdbBtn, mdbInput} from 'mdbvue';
import ModalFormularioPagos from '@/components/modals/FormularioPagos.vue';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import TomarCantidad from '@/components/TomarCantidad.vue';
import {apiPost} from '@/funciones/api.js';
import {fechaHoraActual} from '@/funciones/funciones.js';
export default {
  name: 'NuevaActividad',
  components: {
    mdbBtn,
    mdbInput,
    AlertaMensaje,
    TomarCantidad,
    ModalFormularioPagos,
  },
  props: {
    mostrarAsideActividad: {
      type: Boolean,
      required: true,
    },
    idSucursal: {
      type: String,
      required: true,
    },
    idPlanificacionSeleccionada: {
      type: String,
      required: false,
    },
    empresa: {
      type: Object,
      required: true,
    },
    soloEnviarActividad: {
      type: Boolean,
      required: false,
    },
    adicionalesSucursal: {
      type: Object,
      required: false,
    },
    adicionalesDatosPago: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      observacionSwitch: false,
      existeDatosAlmacenados: false,
      botonDeshabilitado: false,
      TomarCantidad,
      esDevolucion: false,
      esInventario: false,
      esPedido: false,
      mostrarModalTomarCantidad: false,
      alertaMensaje: {
        contenido: '',
      },
      actividades: {
        contacto: null,
        pedido: null,
        inventario: null,
        cobranza: null,
        adicionales: [],
        devoluciones: [],
      },
      planificacion: [],
      observaciones: '',
      act: [],
      pedido: {
        presentaciones: {},
        pag: '',
        doc: '',
        dsc: '',
        obs: '',
        tpre: '',
      },
      inventario: {
        presentaciones: {},
        obs: '',
      },
      devolucion: {
        presentaciones: {},
        obs: '',
      },
      // datos para el formulario de pagos
      BancosPago: [],
      MetodosPago: [],
      MonedasPago: [],
      FormularioPagos: false,
      // Creacion de cobranza
      pago: {},
      pagoActualGuardado: {},
      planificaciones: {},
    };
  },
  watch: {
    mostrarAsideActividad(estaAbierto) {
      if (estaAbierto) {
        this.obtenerDatosGuardados();
        this.obtenerPlanificacion();
      }
    },
    planificaciones() {
      if (this.idPlanificacionSeleccionada) {
        this.planificaciones.forEach((pla) => {
          if (pla.id_pla === this.idPlanificacionSeleccionada) {
            this.planificacion = pla;
          }
        });
      } else if (Object.keys(this.planificaciones).length == 1) {
        for (const key in this.planificaciones) {
          if (this.planificaciones[key].id_pla) {
            this.planificacion = this.planificaciones[key];
          }
        }
      }
    },
  },
  methods: {
    // Metodos para consultar datos del formulario de pagos
    async obtenerBancos() {
      this.cargando = true;
      try {
        const {data} = await apiPost({s: 'cuentasMv'}, '');
        this.BancosPago = data.cuentas;
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un error obteniendo los bancos',
          tipo: 'error',
        };
      } finally {
        this.cargando = false;
      }
    },
    async obtenerMetodos() {
      this.cargando = true;
      try {
        const {data} = await apiPost({s: 'metodosPagoMv'}, '');
        this.MetodosPago = data.metodos;
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un error obteniendo los métodos de pago',
          tipo: 'error',
        };
      } finally {
        this.cargando = false;
      }
    },
    async obtenerMonedas() {
      this.cargando = true;
      try {
        const {data} = await apiPost({s: 'monedasMv'}, '');
        this.MonedasPago = data.monedas;
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un error obteniendo las monedas de pago',
          tipo: 'error',
        };
      } finally {
        this.cargando = false;
      }
    },
    async obtenerPlanificacion() {
      this.cargando = true;
      try {
        const {data} = await apiPost({s: 'planificacion_filtradasMv'}, 'suc=' + this.idSucursal);
        this.planificaciones = data.pla;
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'Ocurrió un error obteniendo las planificaciones',
          tipo: 'error',
        };
      } finally {
        this.cargando = false;
      }
    },
    // Fin metodos formulario de pagos
    enviarActividad() {
      if (!this.planificacion.id_pla) {
        return this.mostrarAlerta('Seleccionar una planificación es requerido', 'advertencia');
      }
      if (!this.actividades.contacto || (!this.actividades.pedido && !this.actividades.inventario)) {
        return this.mostrarAlerta('Las actividades de Contacto, Pedido e Inventario son requeridas', 'advertencia');
      }
      if (this.actividades.pedido === 3 && !this.actividades.inventario) {
        return this.mostrarAlerta('El inventario es requerido', 'advertencia');
      }
      this.act = Object.values(this.actividades).filter((value) => value !== null).flatMap((value) => value);
      if (window.localStorage.getItem(`actividades-${this.empresa.id_emp}-${this.idSucursal}`)) {
        window.localStorage.removeItem(`actividades-${this.empresa.id_emp}-${this.idSucursal}`);
      }
      if (window.localStorage.getItem(`datos-actividad${this.empresa.id_emp}-${this.idSucursal}`)) {
        window.localStorage.removeItem(`datos-actividad${this.empresa.id_emp}-${this.idSucursal}`);
      }
      // Eliminar la actividad del listado de actividades
      let actividadesGuardadas = [];
      let emitirLimpieza = false;
      if (window.localStorage.getItem(`actividades`)) {
        const actividadesGuardadasSinFormato = JSON.parse(window.localStorage.getItem(`actividades`));
        actividadesGuardadas = actividadesGuardadasSinFormato.filter((a) => {
          return a.id_act !== `${this.empresa.id_emp}-${this.idSucursal}`;
        });
        window.localStorage.removeItem(`actividades`);
        window.localStorage.setItem('actividades', JSON.stringify(actividadesGuardadas));
        emitirLimpieza = true;
      }
      // Enviar datos API
      const datosAPI = {
        suc: this.idSucursal,
        actividades: {
          act: JSON.parse(JSON.stringify(this.act)),
        },
        planificacion: this.planificacion.id_pla,
        obs: this.observaciones,
      };
      // Si se tomo una cobranza se enviaran los datos de la cobranza
      if (this.act.includes(6)) {
        // En caso se seleccione cobranza efectiva, verifica que exista información sobre la cobranza (pago)
        if (!Object.values(this.pago).length) {
          return this.mostrarAlerta('Se marcó la actividad Cobranza efectiva. Debes ingresar los datos de la cobranza para continuar', 'advertencia');
        }
        Object.assign(datosAPI, {
          pago: JSON.parse(JSON.stringify(this.pago)),
        });
      }
      if (this.act.includes(3)) {
        // En caso se seleccione tomar pedido, verifica que exista presentación
        if (!Object.values(this.pedido.presentaciones).length) {
          return this.mostrarAlerta('Se marcó la actividad Toma de pedido. Debes ingresar un producto para continuar', 'advertencia');
        }
        Object.assign(datosAPI, {
          pedido: JSON.parse(JSON.stringify(this.pedido)),
        });
      }
      if (this.act.includes(7)) {
        if (!Object.values(this.inventario.presentaciones).length) {
          return this.mostrarAlerta('Se marcó la actividad Toma de inventario. Debes ingresar la presentación para continuar', 'advertencia');
        }
        Object.assign(datosAPI, {
          inventario: JSON.parse(JSON.stringify(this.inventario)),
        });
      }
      if (this.act.includes(12)) {
        if (!Object.values(this.devolucion.presentaciones).length) {
          return this.mostrarAlerta('Se marcó la actividad Toma nota de crédito. Debes ingresar la presentación para continuar', 'advertencia');
        }
        Object.assign(datosAPI, {
          devolucion: JSON.parse(JSON.stringify(this.devolucion)),
        });
      }
      // validaciones de campos
      if (!this.act.includes(4) && !this.act.includes(3)) {
        return this.mostrarAlerta('Especifica si se realizó o no la toma de pedido', 'advertencia');
      }
      if (!this.act.includes(7) && !this.act.includes(11)) {
        return this.mostrarAlerta('Especifica si se realizó o no la toma de inventario', 'advertencia');
      }
      this.botonDeshabilitado = true;
      apiPost({s: 'actividadCrearMv'}, JSON.stringify(datosAPI))
          .then((response) => {
            if (response.data.r && !response.data.e.includes('No se recibió el identificador de la sucursal')) {
              if (!response.data.ped) {
                this.mostrarAlerta('Se registró el medio de contacto, complete la información del cliente antes de realizar una acción diferente.', 'secundario');
                return;
              } else {
                this.mostrarAlerta('Actividad enviada correctamente', 'correcto');
              }
              this.$emit('actualizar');
              this.botonDeshabilitado = false;
              setTimeout(() => {
                this.cerrarNuevaActividad();
              }, 2500);
              if (emitirLimpieza) {
                this.$emit('limpiarActividadGuardada', emitirLimpieza);
              }
              return;
            }
            this.mostrarAlerta(`${response.data.e}`, 'error');
            this.botonDeshabilitado = false;
          })
          .catch(() => {
            this.botonDeshabilitado = false;
            this.mostrarAlerta('Ocurrió un error enviando las actividades. Los datos serán almacenados para intentar nuevamente', 'error');
            setTimeout(() => {
              if (this.soloEnviarActividad) return;
              this.guardarEnviarLuego();
            }, 3000);
          });
    },
    abrirTomarCantidad(tipo) {
      this.mostrarModalTomarCantidad = true;
      this.esInventario = tipo === 'inventario';
      this.esPedido = tipo === 'pedido';
      this.esDevolucion = tipo === 'devolucion';
    },
    tomarPago(event) {
      this.pago = JSON.parse(JSON.stringify(event));
    },
    guardarPagoActual(event) {
      this.pagoActualGuardado = JSON.parse(JSON.stringify(event));
    },
    tomarInventario(event) {
      const datosInventarios = JSON.parse(JSON.stringify(event));
      Object.assign(this.inventario.presentaciones, {
        ...datosInventarios,
      });
    },
    tomarDevolucion(event) {
      const datosDevolucion = JSON.parse(JSON.stringify(event));
      Object.assign(this.devolucion.presentaciones, {
        ...datosDevolucion,
      });
    },
    tomarPedido(event) {
      const {pago, pedido} = JSON.parse(JSON.stringify(event));
      Object.assign(this.pedido.presentaciones, {
        ...pedido,
      });
      Object.assign(this.pedido, {
        ...pago,
      });
    },
    cerrarNuevaActividad() {
      this.$emit('cerrar', false);
      this.limpiarCampos();
    },
    mostrarAlerta(contenido, tipo) {
      this.alertaMensaje = {
        contenido,
        tipo,
      };
    },
    limpiarCampos() {
      this.alertaMensaje = {
        contenido: '',
      };
      this.actividades = {
        contacto: null,
        pedido: null,
        inventario: null,
        cobranza: null,
        adicionales: [],
        devoluciones: [],
      };
      this.act = [];
      this.pedido = {
        presentaciones: {},
      };
      this.inventario = {
        presentaciones: {},
      };
      this.devolucion = {
        presentaciones: {},
      };
      this.pago = {};
      this.pagoActualGuardado = {};
      this.esDevolucion = false;
      this.esInventario = false;
      this.esPedido = false;
      this.mostrarModalTomarCantidad = false;
      this.observaciones = '';
      this.observacionSwitch = false;
      this.planificacion = [];
    },
    obtenerDatosGuardados() {
      const seleccionActividad = window.localStorage.getItem(`actividades-${this.empresa.id_emp}-${this.idSucursal}`);
      const datosActividad = window.localStorage.getItem(`datos-actividad${this.empresa.id_emp}-${this.idSucursal}`);
      if (seleccionActividad) {
        this.actividades = JSON.parse(seleccionActividad);
      }
      if (datosActividad) {
        const {act, pedido, inventario, devolucion, pago, pagoActualGuardado, pla, obs} = JSON.parse(datosActividad);
        this.existeDatosAlmacenados = true;
        this.act = act;
        this.pago = pago;
        this.pagoActualGuardado = pagoActualGuardado;
        this.pedido = pedido;
        this.inventario = inventario;
        this.devolucion = devolucion;
        this.observaciones = obs;
        this.planificacion = pla;
      };
      if (this.observaciones.length) {
        this.observacionSwitch = true;
      };
      if (seleccionActividad && datosActividad) {
        setTimeout(() => {
          this.mostrarAlerta('Actividades almacenadas han sido obtenidas correctamente', 'correcto');
        }, 2500);
      }
    },
    guardarEnviarLuego() {
      if (!this.actividades.contacto || !this.actividades.pedido) {
        return this.mostrarAlerta('Las actividades de Contacto, Pedido e Inventario son requeridas', 'advertencia');
      }
      if (this.actividades.pedido === 3 && !this.actividades.inventario) {
        return this.mostrarAlerta('El inventario es requerido', 'advertencia');
      }
      const act = Object.values(this.actividades).filter((value) => value !== null).flatMap((value) => value);
      // Si se tomo una cobranza se enviaran los datos de la cobranza
      if (act.includes(6)) {
        // En caso se seleccione cobranza efectiva, verifica que exista información sobre la cobranza (pago)
        if (!Object.values(this.pago).length) {
          return this.mostrarAlerta('Se marco en la actividad cobranza efectiva. Debes ingresar los datos de la cobranza para continuar', 'advertencia');
        }
        // Object.assign(datosAPI, {
        //   pago: JSON.parse(JSON.stringify(this.pago)),
        // });
      }
      if (act.includes(3)) {
        if (!Object.values(this.pedido.presentaciones).length) {
          return this.mostrarAlerta('Se marco en la actividad toma de pedido. Debes ingresar una cantidad para continuar', 'advertencia');
        }
      }
      if (act.includes(7)) {
        if (!Object.values(this.inventario.presentaciones).length) {
          return this.mostrarAlerta('Se marco en la actividad toma de inventario. Debes ingresar la presentación para continuar', 'advertencia');
        }
      }
      if (act.includes(12)) {
        if (!Object.values(this.devolucion.presentaciones).length) {
          return this.mostrarAlerta('Se marco en la actividad toma nota de crédito. Debes ingresar la presentación para continuar', 'advertencia');
        }
      }
      // validaciones de campos
      if (!act.includes(4) && !act.includes(3)) {
        return this.mostrarAlerta('Especifica si se realizó o no la toma de pedido', 'advertencia');
      }
      if (!act.includes(7) && !act.includes(11)) {
        return this.mostrarAlerta('Especifica si se realizó o no la toma de inventario', 'advertencia');
      }
      // Agregar la actividad a un listado de actividades por enviar
      let actividadesGuardadas = [];
      if (window.localStorage.getItem(`actividades`)) {
        const actividadesGuardadasSinFormato = JSON.parse(window.localStorage.getItem(`actividades`));
        // caso: guardar una actividad ya guardada anteriormente, se elimina para volverse a agregar con datos nuevos
        actividadesGuardadas = actividadesGuardadasSinFormato.filter((a) => {
          return a.id_act !== `${this.empresa.id_emp}-${this.idSucursal}`;
        });
        window.localStorage.removeItem(`actividades`);
      }
      actividadesGuardadas.push({
        id_act: `${this.empresa.id_emp}-${this.idSucursal}`,
        id_emp: this.empresa.id_emp,
        id_suc: this.idSucursal,
        nom_emp: this.empresa.nom_emp,
        nom_suc: this.adicionalesSucursal.nom_suc,
        sec_zon: this.adicionalesSucursal.sec_zon,
        est_zon: this.adicionalesSucursal.est_zon,
        ciu_zon: this.adicionalesSucursal.ciu_zon,
        fec_cre_act: fechaHoraActual(),
        acciones: this.convertirAcciones(act),
      });
      window.localStorage.setItem('actividades', JSON.stringify(actividadesGuardadas));
      // Guardar datos específicos de la actividad
      window.localStorage.setItem(`actividades-${this.empresa.id_emp}-${this.idSucursal}`, JSON.stringify(this.actividades));
      const datosLocalStorage = {
        act,
        pedido: JSON.parse(JSON.stringify(this.pedido)),
        inventario: JSON.parse(JSON.stringify(this.inventario)),
        devolucion: JSON.parse(JSON.stringify(this.devolucion)),
        pago: JSON.parse(JSON.stringify(this.pago)),
        pagoActualGuardado: JSON.parse(JSON.stringify(this.pagoActualGuardado)),
        pla: JSON.parse(JSON.stringify(this.planificacion)),
        obs: JSON.parse(JSON.stringify(this.observaciones)),
      };
      window.localStorage.setItem(`datos-actividad${this.empresa.id_emp}-${this.idSucursal}`, JSON.stringify(datosLocalStorage));
      this.mostrarAlerta('Actividad almacenada para ser enviada en otro momento', 'correcto');
      setTimeout(() => {
        this.$emit('cerrar', false);
        this.limpiarCampos();
      }, 2500);
    },
    convertirAcciones(arr) {
      if (!arr) return;
      const diccionarioAcciones = {
        // Acciones disponibles
        1: 'Visita',
        2: 'Llamada',
        3: 'Hizo pedido',
        4: 'No hizo pedido',
        5: 'Entrega de pedido',
        6: 'Cobranza efectiva',
        7: 'Toma de inventario',
        9: 'Cobranza no efectiva',
        10: 'Merchandising',
        11: 'No tiene inventario',
        12: 'Devolución',
      };
      return arr.map((a) => (diccionarioAcciones[a]));
    },
  },
};
</script>

<style lang="scss" scoped>
header.font-weight-bold {
  margin: .5rem 0 0;
  text-align: center;
  @include agregar-prefijos(user-select, none);

  @media screen and (min-width: 992px) {
    margin: .5rem 0 0;
  }
}
.contenedor-opciones {
  height: calc(100vh - 100px - 136px); // - titulo contenedor - botones de envío
  overflow-y: auto;
  padding: 0 0 2rem;

  @media screen and (min-width: 310px) {
    height: calc(100vh - 116px - 118px); // - titulo contenedor - botones de envío
  }

  @media screen and (min-width: 576px) {
    height: calc(100vh - 92px - 70px); // - titulo contenedor - botones de envío
  }

  @media screen and (min-width: 768px) {
    height: calc(100vh - 148px - 70px); // - titulo contenedor - botones de envío
    padding: 0 0 1rem;
  }

  input[type="radio"],
  input[type="checkbox"] {
    display: none;

    &:checked + .opciones-actividad {
      border-color: $primario;
      color: $primario;
      font-weight: 500;
    }
  }
}
.opciones-actividad {
  align-items: center;
  border: 1px solid $gris-tr-borde;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  font-size: .85rem;
  margin: .5rem 0;
  padding: .5rem;
  text-align: center;
  width: 100%;
  @include agregar-prefijos(transition, all .25s ease);
  @include agregar-prefijos(user-select, none);

  @media screen and (min-width: 576px) {
    font-size: .9rem;
  }

  svg {
    height: 18px;
    margin: 0 .25rem 0 0;
    width: 18px;
  }
}
</style>
