<template>
  <article class="contenedor-hijo">
    <div class="container">
      <div class="row justify-content-center justify-content-md-between mb-3 ml-2 ml-sm-0">
        <div class="col-auto px-0">
          <mdb-btn
            flat
            dark-waves
            icon="arrow-left"
            type="button"
            title="Regresar"
            @click="$router.go(-1)"
          >
            Regresar
          </mdb-btn>
        </div>
      </div>
      <div
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </div>
      <div v-else>
        <div class="encabezado-detalles">
          <header class="h4-responsive text-center pt-3">Detalles de la devolución</header>
          <div
            v-if="devolucion && devolucion.length"
            class="row mx-0 mt-2"
          >
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Estado</span>
              <p
                :class="!(devolucion && devolucion[0].est_dev)
                  ?'text-muted font-italic user-select-none'
                  : asignarColorEstado(devolucion[0].est_dev) + ' font-weight-bold'"
              >
                {{
                  devolucion && devolucion[0].est_dev
                    ? devolucion[0].est_dev
                    : 'Sin especificar'
                }}
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Número de Devolución</span>
              <p class="text-capitalize">{{ $route.params.id }}</p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Fecha</span>
              <p :class="devolucion[0] && devolucion[0].fec_dev ? 'text-capitalize' : 'text-muted font-italic user-select-none'">
                {{ devolucion[0] && devolucion[0].fec_dev ? devolucion[0].fec_dev : 'Sin fecha especificada' }}
              </p>
            </div>
            <div class="col-12 col-sm-6 col-xl-4">
              <span class="small-block font-titulo">Cliente, Sucursal</span>
              <p>
                <span :class="devolucion[0] && !devolucion[0].nom_emp.length ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{ devolucion[0] && devolucion[0].nom_emp ? devolucion[0].nom_emp : 'Cliente no especificado' }},
                </span>
                <span :class="devolucion[0] && !devolucion[0].nom_suc.length ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{ devolucion[0] && devolucion[0].nom_suc ? devolucion[0].nom_suc : 'Sucursal no especificada' }}
                </span>
              </p>
            </div>
            <div class="col-12 col-sm-6 col-xl-4">
              <span class="small-block font-titulo">Vendedor</span>
              <p>
                <span :class="devolucion[0] && (!devolucion[0].nom_per || !devolucion[0].ape_per) ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{ devolucion[0] && (devolucion[0].nom_per || devolucion[0].ape_per) ? devolucion[0].nom_per + ' ' + devolucion[0].ape_per : 'Vendedor no especificado' }}
                </span>
              </p>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
              <span class="small-block font-titulo">Dirección</span>
              <p class="text-capitalize">
                {{ devolucion[0] && devolucion[0].dir_suc }},
                {{ devolucion[0] && devolucion[0].sec_zon }},
                {{ devolucion[0] && devolucion[0].ciu_zon }},
                {{ devolucion[0] && devolucion[0].est_zon }}.
              </p>
            </div>
            <div class="col-12 col-md-6 col-xl">
              <span class="small-block font-titulo">Observación</span>
              <p
                :class="{
                  'text-muted font-italic user-select-none': devolucion[0] && !devolucion[0].obs_dev.length,
                }"
              >
                {{
                  (devolucion[0] && devolucion[0].obs_dev && devolucion[0].obs_dev) ||
                  'Sin especificar'
                }}
              </p>
            </div>
          </div>
          <div
            v-else
            class="mensaje-no-items py-2"
          >
            <font-awesome-icon icon="file-invoice" size="5x" class="icono" />
            <p class="texto">Sin detalles de la devolución</p>
          </div>
        </div>
        <ul class="table devolucion">
          <li class="encabezado sticky">
            <header class="h4-responsive">Productos en devolución</header>
            <p class="descripcion">
              Productos listados: {{ notasDevolucion && notasDevolucion.length }}
            </p>
            <div class="thead claro">
              <div class="col">
                Nombre
                <span class="small-block">Categoría</span>
              </div>
              <div class="col-12 col-sm-3">
                Descripción
                <span class="small-block">Empaque - Código</span>
              </div>
              <div class="col-12 col-sm-2 text-right">
                Cantidad
                <span class="small-block">Unidad</span>
              </div>
              <div class="col">
                Observación
              </div>
            </div>
          </li>
          <li
            v-if="notasDevolucion && !notasDevolucion.length"
            class="mensaje-no-items py-3"
          >
            <font-awesome-icon icon="file-invoice" size="5x" class="icono" />
            <p class="texto text-center">No existen productos en devolución</p>
          </li>
          <li
            class="contenido"
            v-for="(producto, i) in notasDevolucion"
            :key="`${producto.id_pro}-${i}`"
          >
            <div
              class="col"
              data-columna="Nombre / Categoría"
            >
              <p>
                {{ producto.nom_pro }}
                <span class="small-block">
                  {{ producto.nom_cat && producto.nom_cat }}
                </span>
              </p>
            </div>
            <div
              class="col-12 col-sm-3"
              data-columna="Descripción / Empaque - Código"
            >
              <p>
                {{ producto.des_pre }}
                <span class="small-block">
                  {{ producto.tip_pre }} - {{ producto.cod_pre ? producto.cod_pre : 0 }}
                </span>
              </p>
            </div>
            <div
              class="cl-12 col-sm-2"
              data-columna="Cantidad / Unidad"
            >
              <p class="text-right">
                  {{ producto.can_nd}}
                  <span class="small-block">
                    {{ producto.can_ind_nd }}
                  </span>
              </p>
            </div>
            <div
              class="col"
              data-columna="Observación"
            >
              <p>
                {{producto.obs_nd.slice(0,1).toUpperCase() + producto.obs_nd.slice(1)}}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <AlertaMensaje
      :alertaMensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </article>
</template>

<script>
import {apiPost} from '@/funciones/api.js';
import {
  mdbBtn,
} from 'mdbvue';
import AlertaMensaje from '@/components/AlertaMensaje.vue';
import {asignarColorEstado} from '@/funciones/funciones.js';
import LoaderKel from '@/components/LoaderKel.vue';
export default {
  name: 'Devolucion',
  components: {
    mdbBtn,
    AlertaMensaje,
    LoaderKel,
  },
  data() {
    return {
      asignarColorEstado,
      devolucion: [],
      notasDevolucion: [],
      cargando: false,
      alertaMensaje: {
        contenido: '',
      },
    };
  },
  mounted() {
    this.obtenerDetallesDevolucion();
  },
  methods: {
    async obtenerDetallesDevolucion() {
      this.cargando = true;
      if (!this.$route.params.id && window.location.pathname === '/devolucion/') {
        this.$router.push('/devoluciones');
        return;
      }
      try {
        const {data} = await apiPost(
            {s: 'devolucionMv'},
            `dev=${this.$route.params.id}`,
        );
        if (data) {
          this.devolucion = data.dev;
          this.notasDevolucion = data.not;
        }
        this.cargando = false;
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'No se pudo obtener los detalles de la devolución',
          tipo: 'error',
        };
        this.cargando = false;
        this.$router.push('/devoluciones');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table.devolucion {
  margin: 2rem auto 3rem;
  $breakpointToChange: "576px";
  @include ul-table-responsive-at ($breakpointToChange);
}
</style>
